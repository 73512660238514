export const TYPE = {
   TEAM: 'TEAM',
   PLAYER: 'PLAYER',
   MATCHUP: 'MATCHUP',
   REALTIME: 'REALTIME'
}

export const PERFORMANCEOPTIONS = {
   REALTIME: [
      {
         name: 'Batters',
         url: ''
      },
      {
         name: 'Bowlers',
         url: ''
      }
   ],

   PLAYER: [
      {
         name: 'Batter Recent Form',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/Batsman?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      },
      {
         name: 'Bowlers Recent Form',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/Bowlerform-Dotballs?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      }
   ],
   TEAM: [
      {
         name: 'Win/Loss Record Over Time',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/WinLostRecord?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      },
      {
         name: 'Average Runs Scored/Conceded',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/RunsbyTeam?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      },
      {
         name: 'Head-to-Head Records',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/HeadtoHead?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      },
      {
         name: 'Catch and Bowled Success Rate',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/WicketTypes?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      },
      {
         name: 'Team Boundry Rate',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/BoundariesinMatch?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      }
   ], MATCHUP: [
      {
         name: 'Batsman vs. Each Bowler',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/BatteragainstEachBowler?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      },
      {
         name: 'Bowler vs. Each Batsman',
         url: 'https://public.tableau.com/views/M3ta11-btbazrsheetsz/BowleragainstEachBatter?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link'
      }
   ]
}
