import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.scss";
import App from "./App";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/auth";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import ReactGA from 'react-ga4';
import "./localization/config";

const TRACKING_ID = "G-48Q1LJWPP1";
ReactGA.initialize(TRACKING_ID);

const composeEnhancers = window.__REDUX__DEVTOOLS_EXTENSION_COMPOSE__ || compose;

console.warn = () => {
};

console.info = () => {
};

console.profile = () => {
};

if (process.env.REACT_APP_ENV == 'production') {
   console.error = () => {
   };

   console.log = () => {
   };
}

const rootReducer = combineReducers({
   auth: authReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Provider store={store}>
   <Suspense fallback={<div>Loading...</div>}>
      <App/>
   </Suspense>
</Provider>);

if ('serviceWorker' in navigator) {
   window.addEventListener('load', (event) => {
      navigator.serviceWorker.register('./service-worker.js').then((registration) => {
         console.log('Service worker has been registered with scope ' + registration.scope);
      }).catch((e) => {
         console.log('Service worker registration failed:', e);
      });
   })
}

