import React, {useEffect} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import {connect} from "react-redux";
import * as actions from "./store/actions";
import "./assets/css/global.css";
import "./assets/css/elements.css";
import "./assets/css/responsive.scss";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {v4 as uuidv4} from 'uuid';
import {pubnubLiveTokenValidator, pubnubMatchesTokenValidator} from "./helpers/CommonFunctions";

import Match from "./containers/Match/Match";
import LoginV2 from "./containers/Login/Login";
import Latest from "./containers/Latest/Latest";
import MatchesV2 from "./containers/Matches/Matches";
import Error404 from "./containers/Error404/Error404";
import TermsAndConditions from "./containers/TermsAndConditions/TermsAndConditions";
import PrivacyPolicyPage from "./containers/PrivacyPolicy/PrivacyPolicyPage";
import Stats from "./containers/Stats/Stats";

const App = (props) => {
   useEffect(() => {
      if (localStorage.getItem("uuid") == null) {
         localStorage.setItem("uuid", uuidv4())
      }
      pubnubLiveTokenValidator(props.userName)
      pubnubMatchesTokenValidator();
   }, [props.userName]);

   return (<div>
      <ToastContainer
         position="top-right"
         autoClose={5000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         pauseOnFocusLoss={false}
         draggable={false}
         pauseOnHover={false}
         theme="dark"
         limit={3}/>
      {/*<TagHelmet/>*/}
      <BrowserRouter>
         <Routes>
            <Route path="/home" exact element={<Navigate to="/login"/>}/>
            <Route path="/" exact element={<Navigate to="/login"/>}/>
            <Route path="/login" exact element={<LoginV2/>}/>
            <Route path="/match/:matchId" exact element={<Match/>}/>
            <Route path="/matches" exact element={<MatchesV2/>}/>
            <Route path="/latest" exact element={<Latest/>}/>
            <Route path="/stats" exact element={<Stats/>}/>
            <Route path="/privacy-policy" exact element={<PrivacyPolicyPage/>}/>
            <Route path="/terms-and-conditions" exact element={<TermsAndConditions/>}/>
            <Route path="/not-found" exact element={<Error404/>}/>
            <Route path="*" element={<Navigate to="/not-found"/>}/>
         </Routes>
      </BrowserRouter>
   </div>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      name: state.auth.name
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
      onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
