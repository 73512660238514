import classNames from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./Stats.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar"
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import {PERFORMANCEOPTIONS, TYPE} from './SelectOptions';
import {useLocation} from "react-router-dom";

const Stats = (props) => {
   const location = useLocation();
   const statsType = new URLSearchParams(location.search).get('statsType');
   const [selectedType, setSelectedType] = useState(TYPE.REALTIME);
   const [selectedOption, setSelectedOption] = useState(PERFORMANCEOPTIONS[selectedType][0]);

   const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedType(selectedValue);
      setSelectedOption(PERFORMANCEOPTIONS[selectedValue][0]);
   };

   useEffect(() => {
      console.log("statsType", statsType)
      if (statsType) {
         setSelectedType(statsType);
         setSelectedOption(PERFORMANCEOPTIONS[statsType][0]);
      }
   }, [statsType]);

   return (
      <div className={classNames(styles.bodyLeaderBoardOld, "ps-2 pe-2 ps-xxl-0 pe-xxl-0")}>
         <div className={classNames(styles.pageWrapper)}>
            <div
               className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
               <div className="col-12 p-0 row">
                  <NavbarSecondary page="events"/>
               </div>

               <div className="container p-0">
                  <div className="row gx-0">
                     <div className="col-md-12 col-xl-12">
                        <div className={classNames(styles.topLeadersRow, "row gx-0")}>
                           <div className="col-md-12">
                              <div className="row gx-0">
                                 <div className="col-12 ps-3 pe-3">
                                    <div className="fc-wrapper matches-radio-wrapper row mb-4 mb-sm-0 d-flex justify-content-start">
                                       <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                          <input
                                             type="radio"
                                             name="radio"
                                             value={TYPE.REALTIME}
                                             checked={selectedType === TYPE.REALTIME}
                                             onChange={handleRadioChange}
                                          />
                                          <span>Real Time Stats</span>
                                       </label>

                                       <label className="order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 pt-3 pr-1 mb-sm-0">
                                          <span>Based on Past Matches :</span>
                                       </label>
                                       <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                          <input
                                             type="radio"
                                             name="radio"
                                             value={TYPE.TEAM}
                                             checked={selectedType === TYPE.TEAM}
                                             onChange={handleRadioChange}
                                          />
                                          <span>Team Performance</span>
                                       </label>
                                       <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                          <input
                                             type="radio"
                                             name="radio"
                                             value={TYPE.PLAYER}
                                             checked={selectedType === TYPE.PLAYER}
                                             onChange={handleRadioChange}
                                          />
                                          <span>Player Performance</span>
                                       </label>
                                       <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                          <input
                                             type="radio"
                                             name="radio"
                                             value={TYPE.MATCHUP}
                                             checked={selectedType === TYPE.MATCHUP}
                                             onChange={handleRadioChange}
                                          />
                                          <span>Match-Ups</span>
                                       </label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="row gx-0 mt-4">
                           <div className="col-12 ">
                              <div className="col-12 p-0 mt-3">
                                 {PERFORMANCEOPTIONS[selectedType].map(option => (
                                    <button
                                       style={{border: (selectedOption.name === option.name) ? '1px solid #FAFF00' : undefined}}
                                       key={option.name}
                                       className={classNames("blackBtn roundBtn me-3 mb-2 mt-2 mt-sm-0 capitalize ", selectedOption.name === option.name ? "selectedBtn" : "")}
                                       onClick={() => {
                                          setSelectedOption(option);
                                       }}>
                                       {option.name}
                                    </button>
                                 ))}
                              </div>
                           </div>
                        </div>

                        <div className={'mt-4'}>
                           {selectedType === TYPE.REALTIME ?
                              <></> :
                              <tableau-viz id="tableauViz" src={selectedOption.url}></tableau-viz>
                           }

                        </div>

                     </div>
                  </div>
               </div>
            </div>
            <Footer/>
         </div>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(Stats);
