import React, {useEffect, useState} from "react";
import styles from "./ScheduledMatch.module.scss";
import classNames from "classnames";
import * as ScheduleService from "../../services/SchedulerService"
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as DigitalAssetService from "../../services/DigitalAssetService";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {getPlayerHittingRank, playerRoleStyleController} from "../../helpers/CommonFunctions";
import * as StatisticsService from "../../services/StatisticsService";
import {useTranslation} from "react-i18next";

const ScheduledMatchSection = (props) => {

   const location = useLocation();
   const returnUrl = new URLSearchParams(location.search).get('returnUrl');
   const navigate = useNavigate();
   const currentUrl = location.pathname + location.search;
   const [ScheduledMatchData, setScheduledMatchData] = useState({})
   const [teams, setTeams] = useState([])
   const [matchStartTime, setMatchStartTime] = useState('');
   const [timeLeft, setTimeLeft] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
   const [team1Data, setTeam1Data] = useState({});
   const [team2Data, setTeam2Data] = useState({});
   const [loading, setLoading] = useState({});
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [pageLang, setPageLang] = useState(localStorage.getItem('language'));
   const [tournamentData, setTournamentData] = useState(props.matchData.tournament);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const getMatchStats = async () => {
      await StatisticsService.getMatchStat(props.matchId, props.Token).then((response) => {
         if (response.data.apiStatus) {
            setTournamentData(response.data.match.tournament)
         } else if (response.status === 400) {
            toast.error(`You haven't subscribed to the tournament.`);
            setTimeout(() => {
               navigate('/tournaments');
            }, 1000);

         }
      });
   };

   useEffect(() => {
      const fetchData = async () => {
         const MatchData = ScheduleService.getScheduledMatch(props.matchId, pageLang);
         MatchData.then((response) => {
            if (response.apiStatus) {
               setMatchStartTime(response.match.startTime);
               setScheduledMatchData(response.match);
               setTeams(response.match.teamProfiles);
               getTeamData(response.match);
               setTimeLeft(calculateTimeLeft())
            }
         })
      }
      fetchData()
   }, [])

   useEffect(() => {
      const timer = setInterval(() => {
         if (matchStartTime) {
            setTimeLeft(calculateTimeLeft());
         }
      }, 1000);

      return () => {
         clearInterval(timer);
      };
   }, [timeLeft]);

   useEffect(() => {
      if (timeLeft.minutes <= -1 && props.tossWinnerId == null) {
         getMatchStats();
      }
   }, [props.tossWinnerId])

   function calculateTimeLeft() {
      if (matchStartTime) {
         const targetTime = new Date(matchStartTime);
         const currentTime = new Date().toLocaleString("en-US");
         const timeDiff = targetTime - new Date(currentTime);

         // Convert the time difference to days, hours, minutes, and seconds
         const secondsInMilliSeconds = 1000;
         const minutesInMilliSeconds = secondsInMilliSeconds * 60;
         const hoursInMilliSeconds = minutesInMilliSeconds * 60;
         const days = Math.floor(timeDiff / (hoursInMilliSeconds * 24));
         const hours = Math.floor((timeDiff % (hoursInMilliSeconds * 24)) / hoursInMilliSeconds);
         const minutes = Math.floor((timeDiff % hoursInMilliSeconds) / minutesInMilliSeconds);
         const seconds = Math.floor((timeDiff % minutesInMilliSeconds) / secondsInMilliSeconds);

         if (days == 0 && hours == 0 && minutes == 0 && seconds == 15) {
            props.showTwitchVideo(true);
         }
         return {days, hours, minutes, seconds};
      } else {
         return {days: 0, hours: 0, minutes: 0, seconds: 0};
      }
   }

   const getTeamData = async (matchData) => {
      setLoading(true);

      if (matchData?.teamProfiles[0]?.teamId) {
         DigitalAssetService.getTeamProfileDetailsById(matchData.teamProfiles[0].teamId, pageLang).then(
            (response) => {
               if (response.apiStatus) {
                  setTeam1Data(response.teamProfileResponse);
               }
            }).catch(error => console.error('Error occurred when getting team 1 data.', error));
      }

      if (matchData?.teamProfiles[1]?.teamId) {
         DigitalAssetService.getTeamProfileDetailsById(matchData.teamProfiles[1].teamId, pageLang).then(
            (response) => {
               if (response.apiStatus) {
                  setTeam2Data(response.teamProfileResponse)
                  setLoading(false)
               }
            }).catch(error => console.error('Error occurred when getting team 2 data.', error))
      }
   }

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);
   if (!translationsLoaded) {
      return loadingIndicator;
   }

   return (
      <div className="col-12 p-0 row">
         {ScheduledMatchData?.singlePlayer == false ?
            <div className={classNames(styles.TeamMatchWrapper)}>
               <div className="row ps-0 pe-0">
                  <div className="gx-0"
                       style={{display: timeLeft.days >= 0 && timeLeft.hours >= 0 && timeLeft.minutes >= 0 && timeLeft.seconds >= 0 ? 'block' : 'none'}}>
                     <div className={classNames(styles.matchInfoWrapper)}>
                        <Link to={`/${tournamentData?.type?.replace(/_/g, "-")?.toLowerCase()}/${tournamentData?.tournamentId}`} style={{textDecoration: "none", color: "white"}}>
                           <div className={classNames(styles.MatchTournamentHeader)}>{tournamentData?.name || ''}</div>
                        </Link>
                        <div>
                           {/* Team A start */}
                           <div className={classNames(styles.teamAcol)}>
                              <div className={classNames(styles.profileImg)}>
                                 {/* <img src={teams[0]?.playerProfiles[0]?.pictureURL}/> */}
                                 <img src={teams[0]?.pictureURL}/>
                              </div>
                              <div className={classNames(styles.matchIfo)}>
                                 <div className={classNames(styles.playerName)}>{teams[0]?.teamName ? teams[0]?.teamName : "TBA"}</div>
                                 {/* <div className={classNames(styles.score)}>0/0</div>
                              <div className={classNames(styles.overs)}>(0.0)</div> */}
                              </div>
                           </div>
                           {/* Team A end */}

                           {/* other info start */}
                           {matchStartTime ? (<div className={classNames(styles.otherInfoCol)}>
                              <div className={classNames(styles.TimerWrapper)}>
                                 <div className={classNames(styles.timmerText, "heading3 color-white timer-val")}>
                                    {timeLeft.days > 0 ?
                                       <div>
                                          {Math.max(timeLeft.days, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>D</span> <span className={classNames(styles.HinMobile)}>{timeLeft.days > 1 ? 'Days' : 'Day'}</span>
                                          : {Math.max(timeLeft.hours, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>H</span> <span className={classNames(styles.HinMobile)}>Hrs</span>
                                       </div>
                                       : timeLeft.hours > 0 ?
                                          <div>
                                             {Math.max(timeLeft.hours, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>H</span> <span className={classNames(styles.HinMobile)}>Hrs</span>
                                             : {Math.max(timeLeft.minutes, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>M</span> <span className={classNames(styles.HinMobile)}>Min</span>
                                          </div>
                                          :
                                          <div>
                                             {Math.max(timeLeft.minutes, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>M</span> <span className={classNames(styles.HinMobile)}>Min</span>
                                             : {Math.max(timeLeft.seconds, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>S</span> <span className={classNames(styles.HinMobile)}>Sec</span>
                                          </div>
                                    }
                                    <div className={classNames(styles.calculatingTimeMsg)}>{t("SCHEDULED_MATCH_SECTION.UNTIL_MATCH_STARTS")}</div>
                                 </div>

                              </div>
                           </div>) : (<div className={classNames(styles.calculatingTimeMsg)}>
                              <label>{t("SCHEDULED_MATCH_SECTION.CALCULATING_TIME_TO_THE_MATCH")}</label>
                           </div>)}
                           {/* other info end */}

                           {/* Team B start */}
                           <div className={classNames(styles.teamBcol)}>
                              <div className={classNames(styles.profileImg)}>
                                 {/* <img src={teams[1]?.playerProfiles[0]?.pictureURL}/> */}
                                 <img src={teams[1]?.pictureURL}/>
                              </div>
                              <div className={classNames(styles.matchIfo)}>
                                 <div className={classNames(styles.playerName)}>{teams[1]?.teamName ? teams[1]?.teamName : "TBA"}</div>
                                 {/* <div className={classNames(styles.score)}>0/0</div>
                              <div className={classNames(styles.overs)}>(0.0)</div> */}
                              </div>
                           </div>
                           {/* Team B end */}
                        </div>
                     </div>
                  </div>

                  <div className={classNames(styles.ProfilePane, styles.scoreBoard, 'order-1')} style={{
                     textAlign: 'center',
                     display: (timeLeft.minutes < 0 && timeLeft.minutes >= -2) ? 'block' : 'none'
                  }}>
                     <div className={classNames(styles.Text24Solina, 'p-3 lh-lg')}>
                        <div className={classNames(styles.calculatingTimeMsg)}>
                           <label>{t("SCHEDULED_MATCH_SECTION.MATCH_STARTING_SOON")}</label>
                        </div>
                     </div>
                  </div>

                  <div className={classNames(styles.ProfilePane, styles.scoreBoard, 'order-1')}
                       style={{textAlign: 'center', height: 95, display: (timeLeft.minutes < -2) ? 'block' : 'none'}}>
                     <div className={classNames(styles.timeOutMsgWrapper)}>
                        <div className={classNames(styles.Text24Solina, 'p-3 lh-lg')}>
                           {/* <a href="" className={classNames(styles.arrowBtn, styles.arrowBtnBack)} style={{top: "20%"}}><BsChevronLeft/></a> */}
                           {t("SCHEDULED_MATCH_SECTION.REQUEST_TIMED_OUT")} <a className={classNames(styles.clickclr)}
                                                                               onClick={() => {
                                                                                  window.location.reload();
                                                                               }}>{t("SCHEDULED_MATCH_SECTION.CLICK_HERE")}</a>&nbsp;{t("SCHEDULED_MATCH_SECTION.OR_RELOAD_YOUR_BROWSER")}
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row gx-0" style={{position: 'relative'}}>
                  <div className={classNames(styles.backElipsys)}></div>
                  {/*card 1 starts*/}
                  <div className="col-12 col-sm-6 ps-0 pe-sm-2 pe-0 ">
                     <div className={classNames(styles.ProfilePane, 'p-0', 'pt-1 mb-4')}>
                        <div className="row gx-0 pt-3">
                           <div className={classNames('col-12 ps-3 pe-md-0 pe-sm-3 pe-3 col-md-4', 'col-md-4')}>
                              {(teams?.length > 0 && teams[0] != null && !loading) ?
                                 <div className={classNames(styles.profilePicWrapper, 'mb-0')}>
                                    {/* <img src={teams[0]?.playerProfiles[0]?.pictureURL}/> */}
                                    <img src={teams[0]?.pictureURL}/>
                                 </div> : <></>}
                           </div>
                           <div className={classNames('col-12', 'col-md-8', 'ps-3', 'pe-3', 'pe-xl-3')}>
                              <div className={classNames(styles.playerSummary, 'player-wrapper', 'w-100')}>
                                 <div className={classNames(styles.LikeBtnWrapper)}>
                                    {(teams?.length > 0 && teams[0] != null) ? <>
                                       <div style={{position: 'relative'}}>
                                          {/*<button classNames="heartLike" style={{top: 15, right: 15}}>*/}
                                          {/*   <img*/}
                                          {/*      src={HeartActive}*/}
                                          {/*      alt="HeartActive"*/}
                                          {/*      className="heartActive"*/}
                                          {/*      style={{dispaly: 'none'}}*/}
                                          {/*   />*/}
                                          {/*</button>*/}
                                       </div>
                                    </> : ""}
                                 </div>
                                 <div className="teamDetail teamsTeamDetail w-100">
                                    {(teams?.length > 0 && teams[0] != null && !loading) ? <div className="row gx-0 m-0">
                                       <div className="col-12 text-start pt-4 pt-sm-4 pt-md-0">
                                          <label className={classNames(styles.TeamInfoName, "heading19 heading-italic upper-case mb-4")}>
                                             {teams[0]?.teamName}
                                          </label>
                                       </div>
                                       <div
                                          className={classNames(styles.TeamInfoWrapper, "col-12 text-start pt-4 pt-sm-4 pt-md-0")}>
                                          <div className="">
                                             <div className={classNames(styles.indicatorWrap, styles.borderTop1)}>
                                                <div className={classNames(styles.indicatorNum)}>{ScheduledMatchData?.teamProfiles[0]?.playerProfiles?.length || 0}</div>
                                                <div className={classNames(styles.indicatorDesc)}>Players</div>
                                             </div>
                                          </div>
                                          <div className="">
                                             <div className={classNames(styles.indicatorWrap, styles.borderTop2)}>
                                                <div className={classNames(styles.indicatorNum)}>{team1Data?.totalMatchCount || 0}</div>
                                                <div className={classNames(styles.indicatorDesc)}>Matches</div>
                                             </div>
                                          </div>
                                          <div className="">
                                             <div className={classNames(styles.indicatorWrap, styles.borderTop3)}>
                                                <div className={classNames(styles.indicatorNum)}>{ScheduledMatchData?.teamProfiles[0]?.winCount || 0}</div>
                                                <div className={classNames(styles.indicatorDesc)}>Wins</div>
                                             </div>
                                          </div>
                                       </div>
                                    </div> : <div className="row gx-0 m-0">
                                       {/*Team Data is not available*/}
                                    </div>}
                                 </div>
                              </div>
                           </div>
                           <div className="row gx-0 m-0">
                              <div className={classNames(styles.teamCardDescriptionWrapper, "col-12 ps-3 pt-2 pe-3")}>
                                 {ScheduledMatchData?.teamProfiles && !loading ?
                                    <p className={classNames(styles.teamCardDescription, 'body-text4 color-gray2 d-flex mb-0')}
                                       style={{flexDirection: "row", justifyContent: "center"}}>
                                       {ScheduledMatchData?.teamProfiles && !loading ? ScheduledMatchData?.teamProfiles[0]?.teamBio ||
                                          'No Description Available for ' + teams[0]?.teamName : loadingIndicator}
                                    </p>
                                    :
                                    <p className={classNames(styles.teamCardDescriptionForTBAData, 'body-text4 color-gray2 d-flex mb-0')}
                                       style={{flexDirection: "row", justifyContent: "center"}}>
                                       Team data to be announced.
                                    </p>}

                              </div>
                           </div>

                           <div className="row gx-0 m-0">
                              <div className="col-12 ps-3 pt-2 pe-3 pb-3">
                                 <div className={classNames(styles.PlayerScrollWrapper)}>
                                    {
                                       ScheduledMatchData?.teamProfiles[0]?.playerProfiles?.map((player, i) => (
                                          <div className={classNames(styles.indicatorWrap, styles.borderTop2)}>
                                             <div className={classNames(styles.ProfileImg)}>
                                                <img src={player?.pictureURL}/>
                                             </div>
                                             <div className={classNames(styles.indicatorNum)}>{player?.playerName}</div>

                                             <div className={classNames(styles.progressBarWrapper, "progressBarWrapper")}>
                                                <span className="playerRole">{t("PLAYERS.HITTING_POWER")}</span>
                                                <span className="playerLevel">
                                                         {getPlayerHittingRank(player?.playerStat?.hittingPower, pageLang)}
                                                      </span>
                                                <span className="progressGroove">
                                                      <span className="progresStick"
                                                            style={{width: `${player?.playerStat?.hittingPower}%`}}>
                                                         <span className="progressDot"></span>
                                                      </span>
                                                   </span>
                                             </div>
                                             <ul>
                                                <li>
                                                   <label>{t("PLAYERS.PLAYING_ROLE")}</label>
                                                   <span>{playerRoleStyleController(player?.playingRole, pageLang)}</span>
                                                </li>
                                                <li>
                                                   <label>{t("PLAYERS.BATTING_STYLE")}</label>
                                                   <span>  {playerRoleStyleController(player?.battingStyle, pageLang)}</span>
                                                </li>
                                                <li>
                                                   <label>{t("PLAYERS.BOWLING_STYLE")}</label>
                                                   <span>{playerRoleStyleController(player?.bowlingStyle, pageLang)}</span>
                                                </li>
                                             </ul>
                                          </div>
                                       ))
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/*card 1 ends*/}
                  {/*card 2 starts*/}
                  <div className="col-12 col-sm-6 ps-0 ps-sm-2 pe-0">
                     <div className={classNames(styles.ProfilePane, 'p-0', 'pt-1 mb-4')}>

                        <div className="row gx-0 pt-3">
                           <div className={classNames('col-12 ps-3 pe-md-0 pe-sm-3 pe-3 col-md-4')}>
                              {(teams?.length > 0 && teams[1] != null && !loading) ?
                                 <div className={classNames(styles.profilePicWrapper, 'mb-0')}>
                                    {/* <img src={teams[1]?.playerProfiles[0]?.pictureURL}/> */}
                                    <img src={teams[1]?.pictureURL}/>
                                 </div> : <></>}
                           </div>
                           <div className={classNames('col-12 col-md-8 ps-3 pe-3 pe-xl-3')}>
                              <div className={classNames(styles.playerSummary, 'player-wrapper', 'w-100')}>
                                 <div className={classNames(styles.LikeBtnWrapper)}>
                                    {(teams?.length > 0 && teams[1] != null) ?
                                       <div style={{position: 'relative'}}>
                                          {/*<button classNames="heartLike">*/}
                                          {/*   <img*/}
                                          {/*      src={HeartActive}*/}
                                          {/*      alt="HeartActive"*/}
                                          {/*      className="heartActive"*/}
                                          {/*      style={{dispaly: 'none'}}/>*/}
                                          {/*</button>*/}
                                       </div> : <></>}
                                 </div>
                                 <div className="teamDetail teamsTeamDetail w-100">
                                    {(teams?.length > 0 && teams[1] != null && !loading) ? <div className="row gx-0 m-0">
                                       <div className="col-12 text-start pt-4 pt-sm-4 pt-md-0">
                                          <label className="heading19 heading-italic upper-case mb-4">
                                             {teams[1]?.teamName}
                                          </label>
                                       </div>
                                       <div
                                          className={classNames(styles.TeamInfoWrapper, "col-12 text-start pt-4 pt-sm-4 pt-md-0")}>
                                          <div className="">
                                             <div className={classNames(styles.indicatorWrap, styles.borderTop1)}>
                                                <div className={classNames(styles.indicatorNum)}>{ScheduledMatchData?.teamProfiles[1]?.playerProfiles?.length || 0}</div>
                                                <div className={classNames(styles.indicatorDesc)}>Players</div>
                                             </div>
                                          </div>
                                          <div className="">
                                             <div className={classNames(styles.indicatorWrap, styles.borderTop2)}>
                                                <div className={classNames(styles.indicatorNum)}>{team2Data?.totalMatchCount || 0}</div>
                                                <div className={classNames(styles.indicatorDesc)}>Matches</div>
                                             </div>
                                          </div>
                                          <div className="">
                                             <div className={classNames(styles.indicatorWrap, styles.borderTop3)}>
                                                <div className={classNames(styles.indicatorNum)}>{ScheduledMatchData?.teamProfiles[1]?.winCount || 0}</div>
                                                <div className={classNames(styles.indicatorDesc)}>Wins</div>
                                             </div>
                                          </div>
                                       </div>
                                    </div> : <div className="row gx-0 m-0">
                                       {/*Team Data is not available*/}
                                    </div>}
                                 </div>
                              </div>
                           </div>
                           <div className="row gx-0 m-0">
                              <div className={classNames(styles.teamCardDescriptionWrapper, "col-12 ps-3 pt-2 pe-3")}>
                                 {ScheduledMatchData?.teamProfiles && !loading ?
                                    <p className={classNames(styles.teamCardDescription, 'body-text4 color-gray2 d-flex mb-0')}
                                       style={{flexDirection: "row", justifyContent: "center"}}>
                                       {ScheduledMatchData?.teamProfiles && !loading ? ScheduledMatchData?.teamProfiles[1]?.teamBio ||
                                          'No Description Available for ' + teams[1]?.teamName : loadingIndicator}
                                    </p>
                                    :
                                    <p className={classNames(styles.teamCardDescriptionForTBAData, 'body-text4 color-gray2 d-flex mb-0')}
                                       style={{flexDirection: "row", justifyContent: "center"}}>
                                       Team data to be announced.
                                    </p>}
                              </div>
                           </div>
                           <div className="row gx-0 m-0">
                              <div className="col-12 ps-3 pt-2 pe-3 pb-3">
                                 <div className={classNames(styles.PlayerScrollWrapper)}>
                                    {
                                       ScheduledMatchData?.teamProfiles[1]?.playerProfiles?.map((player, i) => (
                                          <div className={classNames(styles.indicatorWrap, styles.borderTop2)}>
                                             <div className={classNames(styles.ProfileImg)}>
                                                <img src={player?.pictureURL}/>
                                             </div>
                                             <div className={classNames(styles.indicatorNum)}>{player?.playerName}</div>

                                             <div className={classNames(styles.progressBarWrapper, "progressBarWrapper")}>
                                                <span className="playerRole">{t("PLAYERS.HITTING_POWER")}</span>
                                                <span className="playerLevel">
                                                         {getPlayerHittingRank(player?.playerStat?.hittingPower, pageLang)}
                                                      </span>
                                                <span className="progressGroove">
                                                      <span className="progresStick" style={{width: `${player?.playerStat?.hittingPower}%`}}>
                                                         <span className="progressDot"></span>
                                                      </span>
                                                   </span>
                                             </div>
                                             <ul>
                                                <li>
                                                   <label>{t("PLAYERS.PLAYING_ROLE")}</label>
                                                   <span>{playerRoleStyleController(player?.playingRole, pageLang)}</span>
                                                </li>
                                                <li>
                                                   <label>{t("PLAYERS.BATTING_STYLE")}</label>
                                                   <span>{playerRoleStyleController(player?.battingStyle, pageLang)}</span>
                                                </li>
                                                <li>
                                                   <label>{t("PLAYERS.BOWLING_STYLE")}</label>
                                                   <span>{playerRoleStyleController(player?.bowlingStyle, pageLang)}</span>
                                                </li>
                                             </ul>
                                          </div>
                                       ))
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/*card 2 ends*/}
               </div>
            </div> :

            <>
               {/* ------------------------------------ */}
               <div className="row ps-0 pe-0 mt-5-">
                  <div className="gx-0" style={{display: timeLeft.days >= 0 && timeLeft.hours >= 0 && timeLeft.minutes >= 0 && timeLeft.seconds >= 0 ? 'block' : 'none'}}>
                     <div className={classNames(styles.matchInfoWrapper)}>
                        {/* Team A start */}
                        <div className={classNames(styles.teamAcol)}>
                           <div className={classNames(styles.profileImg)}>
                              <img src={teams[0]?.playerProfiles[0]?.pictureURL}/>
                           </div>
                           <div className={classNames(styles.matchIfo)}>
                              <div className={classNames(styles.playerName)}>{teams[0]?.teamName ? teams[0]?.teamName : "TBA"}</div>
                              {/* <div className={classNames(styles.score)}>0/0</div>
                              <div className={classNames(styles.overs)}>(0.0)</div> */}
                           </div>
                        </div>
                        {/* Team A end */}

                        {/* other info start */}
                        {matchStartTime ? (
                           <div className={classNames(styles.otherInfoCol)}>
                              <div className={classNames(styles.TimerWrapper)}>
                                 <div className={classNames(styles.timmerText, "heading3 color-white timer-val")}>{
                                    timeLeft.days > 0 ?
                                       <>
                                          <div>
                                             {Math.max(timeLeft.days, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>D</span> <span className={classNames(styles.HinMobile)}>{timeLeft.days > 1 ? 'Days' : 'Day'}</span>
                                             : {Math.max(timeLeft.hours, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>H</span> <span className={classNames(styles.HinMobile)}>Hrs</span>
                                          </div>
                                       </>
                                       : timeLeft.hours > 0 ?
                                          <>
                                             <div>
                                                {Math.max(timeLeft.hours, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>H</span> <span className={classNames(styles.HinMobile)}>Hrs</span>
                                                : {Math.max(timeLeft.minutes, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>M</span> <span className={classNames(styles.HinMobile)}>Min</span>
                                             </div>
                                          </> :
                                          <>
                                             <div>
                                                {Math.max(timeLeft.minutes, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>M</span> <span className={classNames(styles.HinMobile)}>Min</span>
                                                : {Math.max(timeLeft.seconds, 0).toString().padStart(2, '0') || '00'}<span className={classNames(styles.VinMobile)}>S</span> <span className={classNames(styles.HinMobile)}>Sec</span>
                                             </div>
                                          </>
                                 }
                                    <div className={classNames(styles.calculatingTimeMsg)}>{t("SCHEDULED_MATCH_SECTION.UNTIL_MATCH_STARTS")}</div>
                                 </div>

                              </div>
                           </div>) : (<div className={classNames(styles.calculatingTimeMsg)}>
                           <div>{t("SCHEDULED_MATCH_SECTION.CALCULATING_TIME_TO_THE_MATCH")}</div>
                        </div>)}
                        {/* other info end */}

                        {/* Team B start */}
                        <div className={classNames(styles.teamBcol)}>
                           <div className={classNames(styles.profileImg)}>
                              <img src={teams[1]?.playerProfiles[0]?.pictureURL}/>
                           </div>
                           <div className={classNames(styles.matchIfo)}>
                              <div className={classNames(styles.playerName)}>{teams[1]?.teamName ? teams[1]?.teamName : "TBA"}</div>
                              {/* <div className={classNames(styles.score)}>0/0</div>
                              <div className={classNames(styles.overs)}>(0.0)</div> */}
                           </div>
                        </div>
                        {/* Team B end */}
                     </div>
                  </div>

                  <div className={classNames(styles.ProfilePane, styles.scoreBoard, 'order-1')} style={{
                     textAlign: 'center',
                     height: 95,
                     display: (timeLeft.minutes < 0 && timeLeft.minutes >= -2) ? 'block' : 'none'
                  }}>
                     <div className={classNames(styles.Text24Solina, 'p-3 lh-lg')}>
                        <div className={classNames(styles.calculatingTimeMsg)}>
                           <label>{t("SCHEDULED_MATCH_SECTION.MATCH_STARTING_SOON")}</label>
                        </div>
                     </div>
                  </div>

                  <div className={classNames(styles.ProfilePane, styles.scoreBoard, 'order-1')}
                       style={{textAlign: 'center', height: 95, display: (timeLeft.minutes < -2) ? 'block' : 'none'}}>
                     <div className={classNames(styles.timeOutMsgWrapper)}>
                        <div className={classNames(styles.Text24Solina, 'p-3 lh-lg')}>
                           {/* <a href="" className={classNames(styles.arrowBtn, styles.arrowBtnBack)} style={{top: "20%"}}><BsChevronLeft/></a> */}
                           {t("SCHEDULED_MATCH_SECTION.REQUEST_TIMED_OUT")} <a className={classNames(styles.clickclr)}
                                                                               onClick={() => {
                                                                                  window.location.reload();
                                                                               }}>{t("SCHEDULED_MATCH_SECTION.CLICK_HERE")}</a>&nbsp;{t("SCHEDULED_MATCH_SECTION.OR_RELOAD_YOUR_BROWSER")}
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row gx-0" style={{position: 'relative'}}>
                  <div className={classNames(styles.backElipsys)}></div>
                  {/*card 1 starts*/}
                  <div className="col-12 col-sm-6 ps-0 pe-sm-2 pe-0 ">
                     <div className={classNames(styles.ProfilePane, 'p-0', 'pt-1 mb-4')}>
                        <div className="row gx-0 pt-3">
                           <div className={classNames('col-12 ps-3 pe-md-0 pe-sm-3 pe-3 col-md-4', 'col-md-4')}>
                              {(teams?.length > 0 && teams[0] != null && !loading) ?
                                 <div className={classNames(styles.profilePicWrapper, 'mb-0')}>
                                    <img src={teams[0]?.playerProfiles[0]?.pictureURL}/>
                                 </div> : <></>}
                           </div>
                           <div className={classNames('col-12', 'col-md-8', 'ps-3', 'pe-3', 'pe-xl-3')}>
                              <div className={classNames(styles.playerSummary, 'player-wrapper', 'w-100')}>
                                 <div className="like-btn-wrapper">
                                    {(teams?.length > 0 && teams[0] != null) ?
                                       <div style={{position: 'relative'}}>
                                          {/*<button classNames="heartLike" style={{ top: 15, right: 15 }}>*/}
                                          {/*  <img*/}
                                          {/*    src={HeartActive}*/}
                                          {/*    alt="HeartActive"*/}
                                          {/*    className="heartActive"*/}
                                          {/*    style={{ dispaly: 'none' }}*/}
                                          {/*  />*/}
                                          {/*</button>*/}
                                       </div> : ""}
                                 </div>
                                 <div className="teamDetail teamsTeamDetail w-100">
                                    {(teams?.length > 0 && teams[0] != null && !loading) ? <div className="row gx-0 m-0">
                                       <div className="col-12 text-start pt-4 pt-sm-4 pt-md-0">
                                          <label className="heading5 heading-italic upper-case mb-4">
                                             {teams[0]?.teamName}
                                          </label>
                                       </div>
                                       <div className="col-sm-8 col-5 d-flex">
                                          <span className=" pe-2 mb-1" style={{textAlign: 'left'}}>
                            <label className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.NATIONALITY")}</label>
                            <label className="body-text5 color-white upper-case heading-italic w-100">
                                             {teams[0]?.playerProfiles?.[0]?.nationality || '--'}
                                            </label>
                                          </span>
                                       </div>
                                       <div className="col-sm-4 col-2 d-flex">
                                          <span className="pe-2 mb-1">
                            <label className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.AGE")}</label>
                            <label className="body-text5 color-white upper-case heading-italic w-100"
                                   style={{fontSize: 12}}>
                                               {teams[0]?.playerProfiles?.[0]?.age || '--'}
                                            </label>
                                          </span>
                                       </div>
                                       <div className="col-5 col-sm-12 d-flex">
                                          <span className="w-100 pe-2">
                            <label
                               className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.BATTING_STYLE")}</label>
                            <label className="body-text5 color-white upper-case heading-italic w-100"
                                   style={{fontSize: 12}}>
                                             {playerRoleStyleController(teams[0]?.playerProfiles[0]?.battingStyle, pageLang)}
                                            </label>
                                          </span>
                                       </div>
                                    </div> : <div className="row gx-0 m-0">
                                       {/*Team Data is not available*/}
                                    </div>}
                                 </div>
                              </div>
                           </div>

                           <div className={classNames(styles.teamCardDescriptionWrapper, "col-12 ps-3 pt-2 pe-3")}>
                              {ScheduledMatchData?.teamProfiles && !loading ?
                                 <p className={classNames(styles.teamCardDescription, 'body-text4 color-gray2 d-flex mb-0')}
                                    style={{flexDirection: "row", justifyContent: "center"}}>
                                    {ScheduledMatchData?.teamProfiles && !loading ? ScheduledMatchData?.teamProfiles[0]?.playerProfiles[0]?.description ||
                                       'No Description Available for ' + teams[0]?.teamName : loadingIndicator}
                                 </p>
                                 :
                                 <p className={classNames(styles.teamCardDescriptionForTBAData, 'body-text4 color-gray2 d-flex mb-0')}
                                    style={{flexDirection: "row", justifyContent: "center"}}>
                                    Team data to be announced.
                                 </p>}
                           </div>
                           <div className="col-12 col-sm-12 col-md-6 ps-3 pe-1 pt-0 pb-3 mt-3 mt-sm-3 mt-md-3">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}
                                   style={{minHeight: "203px"}}>
                                 {(teams?.length > 0 && teams[0] != null && !loading) ? <>
                                       <span className="shedmatchplayersubwrap">
                    <label className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.SHARPNESS")}</label>
                    <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[0]?.playerProfiles?.[0]?.playerAbilities?.["intellect"] ? `${teams[0]?.playerProfiles?.[0]?.playerAbilities?.["intellect"]}/10` : '--'}</label>
                                      </span>
                                       <span className="shedmatchplayersubwrap">
                    <label className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.AGILITY")}</label>
                    <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[0]?.playerProfiles?.[0]?.playerAbilities?.["dexterity"] ? `${teams[0]?.playerProfiles?.[0]?.playerAbilities?.["dexterity"]}/10` : '--'}</label>
                                      </span>
                                       <span className="shedmatchplayersubwrap">
                    <label className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.STRENGTH")}</label>
                    <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[0]?.playerProfiles?.[0]?.playerAbilities?.["strength"] ? `${teams[0]?.playerProfiles?.[0]?.playerAbilities?.["strength"]}/10` : '--'}</label>
                                      </span>
                                       <span className="shedmatchplayersubwrap">
                    <label className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.STAMINA")}</label>
                    <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[0]?.playerProfiles?.[0]?.playerAbilities?.["stamina"] ? `${teams[0]?.playerProfiles?.[0]?.playerAbilities?.["stamina"]}/10` : '--'}</label>
                                      </span></> :
                                    <span className="shedmatchplayersubwrap">
                    <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{t("SCHEDULED_MATCH_SECTION.PLAYER_DETAILS_NOT_AVAILABLE")}</label>
                                      </span>}
                              </div>
                           </div>
                           <div
                              className={classNames(styles.scoutingReport, "col-12 col-sm-12 col-md-6 ps-1 pe-3 pt-0 pb-3 mt-0 mt-sm-3 mt-md-3")}>
                              <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}
                                   style={{minHeight: "203px"}}>
                                      <span className="w-100 pe-2">
                    <label className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.SCOUTING_REPORT")}</label>
                                         {!loading && teams[0]?.playerProfiles?.[0]?.scoutingPropsList?.map(item =>
                                               <span key={item.id} className="shedmatchplayersubwrap">
                        <label
                           className="body-text5 color-white upper-case heading-italic w-100">{item.scout}</label></span>) ||
                                            <p style={{paddingLeft: "25px", height: "100px", fontSize: "15px"}}>--</p>}
                                      </span>
                              </div>
                           </div>
                        </div>
                        {loading ? <></> : <div className="col-12 p-0 row gx-3 ps-2 pe-2 pt-2 pb-3">
                           <div className="col-6 col-lg-3 mb-2 mb-lg-0">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop1)}>
                                 <div className={classNames(styles.indicatorNum)}>{team1Data.totalMatchCount}</div>
                                 <div
                                    className={classNames(styles.indicatorDesc)}>{t("SCHEDULED_MATCH_SECTION.MATCHES")}</div>
                              </div>
                           </div>
                           <div className="col-6 col-lg-3 mb-2 mb-lg-0">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop2)}>
                                 <div className={classNames(styles.indicatorNum)}>{team1Data.winCount}</div>
                                 <div
                                    className={classNames(styles.indicatorDesc)}>{t("SCHEDULED_MATCH_SECTION.WINS")}</div>
                              </div>
                           </div>
                           <div className="col-6 col-lg-3 ">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop3)}>
                                 <div className={classNames(styles.indicatorNum)}>{team1Data.drawCount}</div>
                                 <div
                                    className={classNames(styles.indicatorDesc)}>{t("SCHEDULED_MATCH_SECTION.DRAWS")}</div>
                              </div>
                           </div>
                           <div className="col-6 col-lg-3 ">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop1)}>
                                 <div className={classNames(styles.indicatorNum)}>
                                    {team1Data.lossCount}
                                 </div>
                                 <div className={classNames(styles.indicatorDesc)}>
                                    {t("SCHEDULED_MATCH_SECTION.LOSSES")}
                                 </div>
                              </div>
                           </div>
                        </div>}
                     </div>
                  </div>
                  {/*card 1 ends*/}
                  {/*card 2 starts*/}
                  <div className="col-12 col-sm-6 ps-0 ps-sm-2 pe-0">
                     <div className={classNames(styles.ProfilePane, 'p-0', 'pt-1 mb-4')}>
                        <div className="row gx-0 pt-3">
                           <div className={classNames('col-12 ps-3 pe-md-0 pe-sm-3 pe-3 col-md-4')}>
                              {(teams?.length > 0 && teams[1] != null && !loading) ?
                                 <div className={classNames(styles.profilePicWrapper, 'mb-0')}>
                                    <img src={teams[1]?.playerProfiles[0]?.pictureURL}/>
                                 </div> : <></>}
                           </div>
                           <div className={classNames('col-12 col-md-8 ps-3 pe-3 pe-xl-3')}>
                              <div className={classNames(styles.playerSummary, 'player-wrapper', 'w-100')}>
                                 <div className="like-btn-wrapper">
                                    {(teams?.length > 0 && teams[1] != null) ?
                                       <div style={{position: 'relative'}}>
                                          {/*<button classNames="heartLike" style={{ top: 15, right: 15 }}>*/}
                                          {/*  <img*/}
                                          {/*    src={HeartActive}*/}
                                          {/*    alt="HeartActive"*/}
                                          {/*    className="heartActive"*/}
                                          {/*    style={{ dispaly: 'none' }}*/}
                                          {/*  />*/}
                                          {/*</button>*/}
                                       </div> : <></>}
                                 </div>
                                 <div className={classNames(styles.teamDetail, "teamDetail teamsTeamDetail w-100")}>
                                    {(teams?.length > 0 && teams[1] != null && !loading) ? <div className="row gx-0 m-0">
                                       <div className="col-12 text-start pt-4 pt-sm-4 pt-md-0">
                                          <label className="heading19 heading-italic upper-case mb-4" style={{fontSize: 20, lineHeight: '18px'}}>
                                             {teams[1]?.teamName}
                                          </label>
                                       </div>
                                       <div className="col-sm-8 col-5 d-flex">
                                          <span className=" pe-2 mb-1" style={{textAlign: 'left'}}>
                            <label className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.NATIONALITY")}</label>
                            <label className="body-text5 color-white upper-case heading-italic w-100"
                                   style={{position: 'relative'}}>
                                             {teams[1]?.playerProfiles?.[0]?.nationality || '--'}
                                            </label>
                                          </span>
                                       </div>
                                       <div className="col-sm-4 col-2 d-flex">
                                          <span className="pe-2 mb-1">
                            <label className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.AGE")}</label>
                            <label className="body-text5 color-white upper-case heading-italic w-100">
                                               {teams[1]?.playerProfiles?.[0]?.age || '--'}
                                            </label>
                                          </span>
                                       </div>
                                       <div className="col-5 col-sm-12 d-flex">
                                          <span className="w-100 pe-2">
                            <label
                               className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.BATTING_STYLE")}</label>
                            <label className="body-text5 color-white upper-case heading-italic w-100">
                                             {playerRoleStyleController(teams[1]?.playerProfiles[0]?.battingStyle, pageLang)}
                                            </label>
                                          </span>
                                       </div>
                                    </div> : <div className="row gx-0 m-0">
                                       {/*Team Data is not available*/}
                                    </div>}
                                 </div>
                              </div>
                           </div>

                           <div className={classNames(styles.teamCardDescriptionWrapper, "col-12 ps-3 pt-2 pe-3")}>
                              {ScheduledMatchData?.teamProfiles && !loading ?
                                 <p className={classNames(styles.teamCardDescription, 'body-text4 color-gray2 d-flex mb-0')}
                                    style={{flexDirection: "row", justifyContent: "center"}}>
                                    {ScheduledMatchData?.teamProfiles && !loading ? ScheduledMatchData?.teamProfiles[1]?.playerProfiles[0]?.description ||
                                       'No Description Available for ' + teams[1]?.teamName : loadingIndicator}
                                 </p>
                                 :
                                 <p className={classNames(styles.teamCardDescriptionForTBAData, 'body-text4 color-gray2 d-flex mb-0')}
                                    style={{flexDirection: "row", justifyContent: "center"}}>
                                    Team data to be announced.
                                 </p>}
                           </div>
                           <div
                              className="col-12 col-sm-12 col-md-6 ps-3 pe-1 ps-sm-3 pe-sm-3 ps-md-3 pe-md-2 pt-0 pb-3 mt-3 mt-sm-3 mt-md-3">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}
                                   style={{minHeight: "203px"}}>
                                 {(teams?.length > 0 && teams[0] != null && !loading) ?
                                    <>
                         <span className="shedmatchplayersubwrap">
                          <label
                             className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.SHARPNESS")}</label>
                          <label
                             className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[1]?.playerProfiles?.[0]?.playerAbilities?.intellect ? `${teams[1]?.playerProfiles?.[0]?.playerAbilities?.["intellect"]}/10` : '--'}</label>
                        </span>
                                       <span className="shedmatchplayersubwrap">
                              <label
                                 className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.AGILITY")}</label>
                              <label
                                 className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[1]?.playerProfiles?.[0]?.playerAbilities?.["dexterity"] ? `${teams[1]?.playerProfiles?.[0]?.playerAbilities?.["dexterity"]}/10` : '--'}</label>
                        </span>
                                       <span className="shedmatchplayersubwrap">
                              <label
                                 className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.STRENGTH")}</label>
                              <label
                                 className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[1]?.playerProfiles?.[0]?.playerAbilities?.["strength"] ? `${teams[1]?.playerProfiles?.[0]?.playerAbilities?.["strength"]}/10` : '--'}</label>
                            </span>
                                       <span className="shedmatchplayersubwrap">
                              <label
                                 className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.STAMINA")}</label>
                              <label
                                 className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{teams[1]?.playerProfiles?.[0]?.playerAbilities?.["stamina"] ? `${teams[1]?.playerProfiles?.[0]?.playerAbilities?.["stamina"]}/10` : '--'}</label>
                        </span></> : <>
                            <span className="shedmatchplayersubwrap">
                              <label
                                 className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{t("SCHEDULED_MATCH_SECTION.PLAYER_DETAILS_NOT_AVAILABLE")}</label>
                            </span>
                                    </>
                                 }
                              </div>
                           </div>
                           <div
                              className={classNames(styles.scoutingReport, "col-12 col-sm-12 col-md-6 ps-1 pe-3 ps-sm-3 pe-sm-3 ps-md-2 pe-md-3 pt-0 pb-3 mt-0 mt-sm-3 mt-md-3")}>
                              <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}
                                   style={{minHeight: "203px"}}>
                    <span className="w-100 pe-2">
                      <label className="body-text5 color-gray2">{t("SCHEDULED_MATCH_SECTION.SCOUTING_REPORT")}</label>
                       {!loading && teams[1]?.playerProfiles?.[0]?.scoutingPropsList?.map(item =>
                             <span key={item.id} className="shedmatchplayersubwrap">
                          <label className="body-text5 color-white upper-case heading-italic w-100">{item.scout}</label>  </span>) ||
                          <p style={{paddingLeft: "25px", height: "100px", fontSize: "15px"}}>--</p>}
                    </span>
                              </div>
                           </div>
                        </div>
                        {loading ? <></> : <div className="col-12 p-0 row gx-3 ps-2 pe-2 pt-2 pb-3">
                           <div className="col-6 col-lg-3 mb-2 mb-lg-0">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop1)}>
                                 <div className={classNames(styles.indicatorNum)}>
                                    {team2Data.totalMatchCount}
                                 </div>
                                 <div className={classNames(styles.indicatorDesc)}>
                                    {t("SCHEDULED_MATCH_SECTION.MATCHES")}
                                 </div>
                              </div>
                           </div>
                           <div className="col-6 col-lg-3 mb-2 mb-lg-0">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop2)}>
                                 <div className={classNames(styles.indicatorNum)}>
                                    {team2Data.winCount}
                                 </div>
                                 <div
                                    className={classNames(styles.indicatorDesc)}>{t("SCHEDULED_MATCH_SECTION.WINS")}</div>
                              </div>
                           </div>
                           <div className="col-6 col-lg-3 ">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop3)}>
                                 <div className={classNames(styles.indicatorNum)}>{team2Data.drawCount}</div>
                                 <div
                                    className={classNames(styles.indicatorDesc)}>{t("SCHEDULED_MATCH_SECTION.DRAWS")}</div>
                              </div>
                           </div>
                           <div className="col-6 col-lg-3 ">
                              <div className={classNames(styles.indicatorWrap, styles.borderTop1)}>
                                 <div className={classNames(styles.indicatorNum)}>{team2Data.lossCount}</div>
                                 <div
                                    className={classNames(styles.indicatorDesc)}>{t("SCHEDULED_MATCH_SECTION.LOSSES")}</div>
                              </div>
                           </div>
                        </div>}
                     </div>
                  </div>
                  {/*card 2 ends*/}
               </div>

               {/* ------------------------------------ */}


               <div
                  className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4 p-0 pe-0 pe-sm-0 pe-md-3 order-2 order-md-1 mt-2 mt-md-0 d-none">
                  <div className={classNames(styles.ProfilePane, 'p-3', 'pt-1', 'pb-1')}>
                     <div className="col-12 row p-3 pb-1">
                        <div className="col-2 p-0">
                           <img className={styles.imageMargin} width={50} src={`${teams[0]?.pictureURL}`}
                                style={{borderRadius: '50%'}}></img>
                        </div>
                        <Link to={{pathname: `/team/${teams[0]?.teamId}`}}
                              className={classNames(styles.text14OSI, 'col-6', 'pl-2', 'align-self-center')}>
                           {teams[0]?.teamName}
                        </Link>

                        <div className="col-4 text-end p-0">
                           <font className={classNames(styles.text24SolinaItaBold)}>0/0</font>
                           <br/>
                           <font className={classNames(styles.text12OS)}>(0.0)</font>
                        </div>
                     </div>

                     <div className="col-12 row p-3 pb-1">
                        <div className="col-2 p-0">
                           <img className={styles.imageMargin} width={50} src={`${teams[1]?.pictureURL}`}
                                style={{borderRadius: '50%'}}></img>
                        </div>
                        <Link to={{pathname: `/team/${teams[1]?.teamId}`}}
                              className={classNames(styles.text14OSI, 'col-6', 'pl-2', 'align-self-center')}>
                           {teams[1]?.teamName}
                        </Link>
                        <div className="col-4 text-end p-0">
                           <font className={classNames(styles.text24SolinaItaBold)}>
                              0/0
                           </font>
                           <br/>
                           <font className={classNames(styles.text12OS)}>(0.0)</font>
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 col-xxl-8 p-0 ps-0 ps-sm-0 ps-md-2 order-1 order-md-2 d-none">
                  <div className={classNames(styles.ProfilePane, 'p-0', 'pt-1')}>
                     <div className={classNames(styles.Text24Solina, 'p-3')}
                          style={{borderBottom: '1px solid #FFFFFF0F'}}>{t("SCHEDULED_MATCH_SECTION.PLAYERS")}</div>
                     <div className="col-12 p-0 row">
                        <div className="col-6 p-0" style={{borderRight: '1px solid #FFFFFF0F'}}>
                           {/* <div className="text-center p-3"
                                     style={{borderBottom: "1px solid #FFFFFF0F"}}>

                                    <div className={classNames(styles.text16OSI, "pl-2")}><img
                                        width={30} src={`${teams[0]?.pictureURL}`}
                                        style={{width: "26px"}}></img>&nbsp;{teams[0]?.teamName.split(" ").length > 1 ? teams[0]?.teamName.split(" ").reduce((code, word) => code + word.slice(0, 1), "") : teams[0]?.teamName.slice(0, 3)}
                                    </div>
                                </div> */}
                           <ul className={classNames(styles.FavoUL)}>
                              {teams[0]?.playerProfiles.map((player) => (
                                 <li key={player?.playerId}>
                                    <img src={`${player?.pictureURL}`} style={{float: 'left', marginRight: '10px'}}></img>
                                    <Link to={{pathname: `/player/${player?.playerId}`}}>
                                       <div className="pl-2">{player?.playerName}
                                          <br/>
                                          <div
                                             className={classNames(styles.text12OS, 'pt-2')}>{playerRoleStyleController(player?.playingRole, pageLang)}</div>
                                       </div>
                                    </Link>
                                 </li>
                              ))}
                           </ul>
                        </div>
                        <div className="col-6 p-0">
                           <ul className={classNames(styles.FavoUL)}>
                              {teams[1]?.playerProfiles.map((player) => (
                                 <li key={player.playerId}>
                                    <img src={`${player?.pictureURL}`} style={{float: 'left', marginRight: '10px'}}></img>
                                    <Link to={{pathname: `/player/${player?.playerId}`}}>
                                       <div className="pl-2">{player?.playerName}
                                          <br/>
                                          <div
                                             className={classNames(styles.text12OS, 'pt-2')}>{playerRoleStyleController(player?.playingRole, pageLang)}</div>
                                       </div>
                                    </Link>
                                 </li>
                              ))}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </>
         }
      </div>
   );
};
const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
   };
};
export default connect(mapStateToProps)(ScheduledMatchSection)
